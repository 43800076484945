import moment from 'moment';
import React, { useCallback } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { moneyFormat } from '~/utils/functions';

import { CustomSeparator } from '../../Components';
import { useValidadorXML } from '../../ValidadorContext';
import { Tr, Table, Button as Danfe } from './styles';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/webpack-resolver';
import { HiOutlineDocumentText } from 'react-icons/hi';
import api from '~/services/api';
import { CircularProgress } from '@material-ui/core';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

export const DadosDoXml: React.FC = () => {
  const {
    dadosDaCapa,
    showXMLData,
    dadosDoXML,
    setShowXMLData,
    hasFornecedor,
    setPdf,
    setIsOpenModal,
    loadingPDF,
    setLoadingPDF,
  } = useValidadorXML();

  const CreateTable = useCallback((fornecedor: any): void => {
    const AlertTable = (
      <Table className="col-sm-12 table-exibition">
        <table className="table">
          <thead>
            <tr>
              <th>CPF/CNPJ</th>
              <th>Nome</th>
              <th>Fantasia</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{fornecedor.CNPJ}</td>
              <td>{fornecedor.xNome}</td>
              <td>{fornecedor.xFant}</td>
            </tr>
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>Logradouro</th>
              <th>Número</th>
              <th>Bairro</th>
              <th>Município</th>
              <th>UF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{fornecedor?.enderEmit.xLgr}</td>
              <td>{fornecedor?.enderEmit.nro}</td>
              <td>{fornecedor?.enderEmit.xBairro}</td>
              <td>{fornecedor?.enderEmit.xMun}</td>
              <td>{fornecedor?.enderEmit.UF}</td>
            </tr>
          </tbody>
        </table>
        <table className="table">
          <thead>
            <tr>
              <th>IE</th>
              <th>CEP</th>
              <th>cMun</th>
              <th>cPais</th>
              <th>Pais</th>
              <th>Telefone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{fornecedor.IE}</td>
              <td>{fornecedor?.enderEmit.CEP}</td>
              <td>{fornecedor?.enderEmit.cMun}</td>
              <td>{fornecedor?.enderEmit.cPais}</td>
              <td>{fornecedor?.enderEmit.xPais}</td>
              <td>{fornecedor?.enderEmit.fone}</td>
            </tr>
          </tbody>
        </table>
      </Table>
    );

    MySwal.fire({
      width: 1200,

      title: 'Informações do Fornecedor',
      html: AlertTable,
    });
  }, []);

  const handleShowFornXMLData = useCallback(() => {
    CreateTable(dadosDoXML?.fornecedor);
  }, [CreateTable, dadosDoXML]);

  const getDanfe = useCallback(async () => {
    setLoadingPDF(true);
    try {
      const { data } = await api.get('/transmitir-nfe/gerar-danfe-entrada', {
        params: {
          cod_loja: dadosDaCapa.cod_loja,
          chave_nfe: dadosDaCapa.chave_nfe,
        },
      });
      if (data.success) {
        setPdf(data.danfe);
        setIsOpenModal(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPDF(false);
    }
  }, [dadosDaCapa, setIsOpenModal, setLoadingPDF, setPdf]);

  return (
    <Accordion defaultActiveKey="0">
      <div className="row accordion-row">
        <div className="col-12" style={{ paddingRight: '0px' }}>
          <CustomSeparator
            label="Dados do XML"
            eventKey={showXMLData}
            onChange={(newEventKey) => {
              setShowXMLData(newEventKey);
            }}
          />
        </div>
      </div>
      <div id="accordionXml" className="accordion">
        <Accordion.Collapse
          as={Button}
          eventKey={showXMLData}
          className="row xml-data"
        >
          <>
            <div className="col-sm-12 table-exibition">
              <table className="table">
                <thead>
                  <tr>
                    <th>Loja</th>
                    <th>CPF/CNPJ</th>
                    <th>Fornecedor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{dadosDaCapa.loja}</td>
                    <td>{dadosDaCapa.num_cpf_cnpj}</td>
                    <td>{dadosDaCapa.fornecedor}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table">
                <thead>
                  <Tr>
                    <th>Num NFe</th>
                    <th>Série</th>
                    <th>Emissão</th>
                    <th>Val. Total NF</th>
                    <th className="th_chave_nfe">Chave NFe</th>
                    <th>Danfe</th>
                  </Tr>
                </thead>
                <tbody>
                  <Tr>
                    <td>{dadosDaCapa.num_nfe}</td>
                    <td>{dadosDaCapa.serie}</td>
                    <td>
                      {moment(
                        createDateWithoutTimezone(dadosDaCapa.emissao),
                      ).format('DD/MM/YYYY')}
                    </td>
                    <td>{moneyFormat(String(dadosDaCapa.val_total_nfe), 2)}</td>
                    <td className="td_chave_nfe">{dadosDaCapa.chave_nfe}</td>
                    <td className="td_button_danfe">
                      <Danfe
                        type="button"
                        onClick={() => {
                          getDanfe();
                        }}
                      >
                        {loadingPDF && (
                          <>
                            <CircularProgress
                              size={12}
                              style={{ color: '#fff', marginRight: '5px' }}
                            />
                            Carregando Danfe
                          </>
                        )}
                        {!loadingPDF && (
                          <>
                            <HiOutlineDocumentText size={18} color="#ffffff" />
                            Danfe
                          </>
                        )}
                      </Danfe>
                    </td>
                  </Tr>
                </tbody>
              </table>
            </div>
            {hasFornecedor === false && (
              <>
                <p style={{ color: '#e31b1b' }}>
                  Fornecedor não cadastrado ou inativo! Regularize a situação
                  para proseguir.{' '}
                  <span
                    onClick={() => {
                      handleShowFornXMLData();
                    }}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Clique para ver os dados do fornecedor
                  </span>
                </p>
              </>
            )}
            {dadosDaCapa.tipo_status_sefaz === 101 && (
              <p style={{ color: '#e31b1b' }}>
                Nota Fiscal cancelada! Validação não permitida.
              </p>
            )}
          </>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};
