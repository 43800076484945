/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';

export const ColumnsTable: CustomGridColumns[] = [
  {
    field: 'index',
    headerName: 'Num. Item',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'cod_produto',
    headerName: 'Código do Produto',
    disableColumnMenu: true,
    width: 200,
    sortable: false,
  },
  {
    field: 'cod_gtin_principal',
    headerName: 'Cód. Barras',
    disableColumnMenu: true,
    width: 150,
    sortable: false,
  },
  {
    field: 'cEAN',
    headerName: 'Cód. Barras XML',
    disableColumnMenu: true,
    width: 150,
    sortable: false,
  },
  {
    field: 'des_produto',
    headerName: 'Descrição',
    disableColumnMenu: true,
    width: 200,
    sortable: false,
  },
  {
    field: 'xProd',
    headerName: 'Descrição XML',
    disableColumnMenu: true,
    width: 200,
    sortable: false,
  },
  {
    field: 'cfop',
    headerName: 'CFOP',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'qtd_embalagem_compra',
    headerName: 'Qtd. Emb',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'des_unidade_compra',
    headerName: 'Des. UN.',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'num_ncm',
    headerName: 'NCM',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'des_trib_ent',
    headerName: 'Trib. Entrada',
    disableColumnMenu: true,
    width: 300,
    sortable: false,
  },
  {
    field: 'des_trib_saida',
    headerName: 'Trib. Saída',
    disableColumnMenu: true,
    width: 300,
    sortable: false,
  },
  {
    field: 'val_pauta_ipv',
    headerName: 'Val. Pauta IVA',
    hide: true,
    disableColumnMenu: true,
    width: 200,
    sortable: false,
  },
  {
    field: 'per_iva',
    headerName: '% IVA',
    hide: true,
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'flg_veda_cred',
    headerName: 'Crédito Vedado?',
    hide: true,
    disableColumnMenu: true,
    width: 200,
    sortable: false,
    renderCell(
      row: any,
    ): React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    > {
      return <span>{row.flg_veda_cred ? 'Sim' : 'Não'}</span>;
    },
  },
  {
    field: 'num_cest',
    hide: true,
    headerName: 'CEST',
    disableColumnMenu: true,
    width: 110,
    sortable: false,
  },
  {
    field: 'per_fcp_ent',
    hide: true,
    headerName: '% FCP Entrada',
    disableColumnMenu: true,
    width: 200,
    sortable: false,
  },
  {
    field: 'per_fcp_saida',
    hide: true,
    headerName: '% FCP Saída',
    disableColumnMenu: true,
    width: 200,
    sortable: false,
  },
];
