import styled from 'styled-components';

export const Tr = styled.tr`
  td.td_button_danfe {
    padding: 0;
    /* border: 0.0625rem solid white; */
  }

  td.td_chave_nfe,
  th.th_chave_nfe,
  td.td_button_danfe {
    @media screen and (max-width: 61.9375rem) {
      display: none;
    }
  }
`;

export const Table = styled.div`
  .td-style {
    white-space: nowrap;
  }
  .editado {
    color: #48778c !important;
    background: #dcfcfb !important;
  }

  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    color: #757575;
    width: 16.6%;
    font-size: 0.8571428571428571em;
    border-left: 1px solid #dee2e6;
  }
  .table tbody tr td input {
    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }
  }
  .table thead tr th {
    width: 16.6%;
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #8850bf;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const Button = styled.button`
  color: #fff;
  width: 100%;
  height: 37px;
  border: none;
  background: #8850bf;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background: #c2a0f8;
  }
`;
