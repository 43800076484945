import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

interface StyleProps {
  background?: string;
  columnWidth?: number;
}

export const Container = styled.div`
  .wrapper {
    position: relative;
    overflow: auto;
    border: 1px solid black;
    white-space: nowrap;
  }

  .MuiTableRow-root {
    &:hover {
      .sticky-col {
        background-color: #f5f5f5;
      }
    }
  }

  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    z-index: 5 !important;
  }
  .sticky-col-row {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    z-index: 2;
  }

  .first-col {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    left: 0px;
  }

  .second-col {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    left: 80px;

    z-index: 1;
    position: sticky;

    background-color: white;

    &:hover {
      z-index: 4;
    }
  }
  .third-col {
    left: 160px;
  }

  .itensTable {
    svg {
      font-size: 24px;
    }
  }

  .td-style {
    white-space: nowrap;
  }
  .editado {
    color: #48778c !important;
    background: #dcfcfb !important;
  }

  .table tbody {
    border: 1px solid #dee2e6 !important;
  }
  .table tbody tr td {
    color: #757575;
    border-left: 1px solid #dee2e6;
  }
  .table thead tr th {
    padding-left: 0px;
    border: none;
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: #8850bf;
    font-weight: 600 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const CustomTableCell = styled(TableCell)<StyleProps>`
  position: sticky;
  right: 0;
  width: 400px !important;
  min-width: ${(props) => props.columnWidth};
  background: ${(props) => (props.background ? props.background : '#ffffff')};
  z-index: 2;

  button {
    color: #48778c;
    &:hover {
      background: ${(props) =>
        props.background ? props.background : '#ffffff'} !important;
      opacity: 0.8;
    }
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;
