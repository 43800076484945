import React, { useCallback, useEffect } from 'react';
import { Accordion, Tab, Tabs, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InputText from '~/components/Inputs/InputText';
import { CustomSeparator } from '../../Components';
import { alteraStatusdoXml } from '../../Funcoes/Validacao';
import { useValidadorXML } from '../../ValidadorContext';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { ButtonCollapse } from './styles';

const MySwal = withReactContent(Swal);

interface FunctionsProps {
  [index: string]: any;
}

export const CapaDivergencias: React.FC = () => {
  const {
    dadosDaCapa,
    dadosDoXML,
    dadosErpDoFornecedor,
    key,
    setKey,
    anchorPoint,
    setAnchorPoint,
    showContextMenu,
    setShowContextMenu,
    setCallableFunction,
    setDadosErpDoFornecedor,
    parametrosValidacao,
    itensDaTabela,
    callableFunction,
    showCapa,
    setShowCapa,
  } = useValidadorXML();

  const onContextMenuClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      e.preventDefault();
      const { id } = e.target as HTMLInputElement;
      setAnchorPoint({ x: e.pageX, y: e.pageY });
      setShowContextMenu(true);
      setCallableFunction(id);
    },
    [setAnchorPoint, setCallableFunction, setShowContextMenu],
  );

  function CreateTable(itens: any): void {
    const AlertTableHeader =
      '<tr><th style="text-align:left;padding-left: 10px;">CAMPOS</th><th>XML</th><th>ERP</th></tr>';

    const AlertTableBody = itens.map((item: any, index: number): string => {
      const [chave, valorErp, valorXml] = item;
      const spaco = '&nbsp;&nbsp;';
      const classe = index % 2 === 0 ? 'even' : 'odd';
      const campos: any = {
        num_cpf_cnpj: 'CNPJ',
        num_rg_ie: 'IE',
        des_fornecedor: 'Descrição',
        des_uf: 'UF',
        des_logradouro: 'Logradouro',
        num_endereco: 'Número',
        des_bairro: 'Bairro',
        num_cep: 'Cep',
        num_telefone: 'Telefone',
        des_cidade: 'Cidade',
      };

      const invalid = valorErp.invalid ? 'invalid-row' : '';

      return `<tr class='${classe} ${invalid}'>
                <td style='text-align: left; padding-left: 10px; font-weight: bold;border-right: 1px solid #f2f2f2; width: 30%;'>${campos[chave]}${spaco}</td>
                <td style='padding-right: 10px;'>${valorXml}${spaco}</td>
                <td>${valorErp.value}</td>
              </tr>`;
    });

    const AlertTable = `
      <style type="text/css">
        .invalid-row {
          background-color: #fdfdae !important;
        }
        .table-body tr:nth-child(even) {
          background-color: #f7f7f7;
        }
      </style>

      <div style='max-height: 300px; border: solid 1px #dcdcdc; overflow: auto'>
        <table style='width:100%;max-width: 1200px;table-layout: fixed;'>
          <thead>${AlertTableHeader}</thead>
          <tbody class="table-body">${AlertTableBody.join(' ')}</tbody>
        </table>
      </div>

      <p style='text-align: left; padding: 10px'></p>
    `;

    MySwal.fire({
      icon: 'info',
      width: 1200,
      title: 'XML X ERP',
      html: String(AlertTable),
    });
  }

  const listOfFunctions: FunctionsProps = {
    attDescricao: async () => {
      if (dadosDoXML?.fornecedor.xNome) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          des_fornecedor: {
            value: dadosDoXML.fornecedor.xNome,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attIeRg: async () => {
      if (dadosDoXML?.fornecedor.IE) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          num_rg_ie: {
            value: dadosDoXML.fornecedor.IE,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attCGC: async () => {
      if (dadosDoXML?.fornecedor.CNPJ) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          num_cpf_cnpj: {
            value: dadosDoXML.fornecedor.CNPJ,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attEndereco: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.xLgr) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          des_logradouro: {
            value: dadosDoXML.fornecedor.enderEmit.xLgr,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attNumEnd: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.nro) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          num_endereco: {
            value: dadosDoXML.fornecedor.enderEmit.nro,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attBairro: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.xBairro) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          des_bairro: {
            value: dadosDoXML.fornecedor.enderEmit.xBairro,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attCep: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.CEP) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          num_cep: {
            value: dadosDoXML.fornecedor.enderEmit.CEP,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attMunicipio: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.xMun) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          des_cidade: {
            value: dadosDoXML.fornecedor.enderEmit.xMun,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attUF: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.UF) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          des_uf: {
            value: dadosDoXML.fornecedor.enderEmit.UF,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attFone: async () => {
      if (dadosDoXML?.fornecedor.enderEmit.fone) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          num_telefone: {
            value: dadosDoXML.fornecedor.enderEmit.fone,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    attTodos: async () => {
      if (dadosDoXML?.fornecedor) {
        setDadosErpDoFornecedor({
          ...dadosErpDoFornecedor,
          num_cpf_cnpj: {
            value: dadosDoXML.fornecedor.CNPJ,
            invalid: false,
          },
          num_rg_ie: {
            value: dadosDoXML.fornecedor.IE,
            invalid: false,
          },
          des_fornecedor: {
            value: dadosDoXML.fornecedor.xNome,
            invalid: false,
          },
          apelido_fantasia: {
            value: dadosDoXML.fornecedor.xFant,
            invalid: false,
          },
          des_uf: {
            value: dadosDoXML.fornecedor.enderEmit.UF,
            invalid: false,
          },
          des_logradouro: {
            value: dadosDoXML.fornecedor.enderEmit.xLgr,
            invalid: false,
          },
          num_endereco: {
            value: dadosDoXML.fornecedor.enderEmit.nro,
            invalid: false,
          },
          des_bairro: {
            value: dadosDoXML.fornecedor.enderEmit.xBairro,
            invalid: false,
          },
          num_cep: {
            value: dadosDoXML.fornecedor.enderEmit.CEP,
            invalid: false,
          },
          num_telefone: {
            value: dadosDoXML.fornecedor.enderEmit.fone,
            invalid: false,
          },
          des_cidade: {
            value: dadosDoXML.fornecedor.enderEmit.xMun,
            invalid: false,
          },
          flg_produtor_rural: {
            value: false,
            invalid: false,
          },
        });
        await alteraStatusdoXml({
          codXml: Number(dadosDaCapa.cod_xml_cap),
          tipoIcmsSt: parametrosValidacao.icms_st,
          TipoIcmsOp: parametrosValidacao.icms_op,
          codPerfil: Number(parametrosValidacao.cod_perfil),
          dadosErpDoFornecedor,
          itensDaTabela,
        });
        return;
      }
      toast.error('Não foi possível atualizar os dados da capa.');
    },
    showDifferences: () => {
      const formatedComparison = Object.entries(dadosErpDoFornecedor).filter(
        (itens) =>
          [
            'des_fornecedor',
            'num_rg_ie',
            'num_cpf_cnpj',
            'des_logradouro',
            'num_endereco',
            'des_bairro',
            'num_cep',
            'des_cidade',
            'des_uf',
            'num_telefone',
          ].indexOf(itens[0]) >= 0,
      );
      for (let i = 0; i < formatedComparison.length; i++) {
        switch (formatedComparison[i][0]) {
          case 'des_fornecedor':
            formatedComparison[i].push(dadosDoXML?.fornecedor.xNome);
            break;
          case 'num_rg_ie':
            formatedComparison[i].push(dadosDoXML?.fornecedor.IE);
            break;
          case 'num_cpf_cnpj':
            formatedComparison[i].push(dadosDoXML?.fornecedor.CNPJ);
            break;
          case 'des_logradouro':
            formatedComparison[i].push(dadosDoXML?.fornecedor.enderEmit.xLgr);
            break;
          case 'num_endereco':
            formatedComparison[i].push(dadosDoXML?.fornecedor.enderEmit.nro);
            break;
          case 'des_bairro':
            formatedComparison[i].push(
              dadosDoXML?.fornecedor.enderEmit.xBairro,
            );
            break;
          case 'num_cep':
            formatedComparison[i].push(dadosDoXML?.fornecedor.enderEmit.CEP);
            break;
          case 'des_cidade':
            formatedComparison[i].push(dadosDoXML?.fornecedor.enderEmit.xMun);
            break;
          case 'des_uf':
            formatedComparison[i].push(dadosDoXML?.fornecedor.enderEmit.UF);
            break;
          case 'num_telefone':
            formatedComparison[i].push(dadosDoXML?.fornecedor.enderEmit.fone);
            break;

          default:
            break;
        }
        // formatedComparison[i].push();
      }

      CreateTable(formatedComparison);
    },
  };

  const handleClick = useCallback(
    () => (showContextMenu ? setShowContextMenu(false) : null),
    [setShowContextMenu, showContextMenu],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // document.addEventListener('contextmenu', onContextMenuClick);
    return () => {
      document.removeEventListener('click', handleClick);
      // document.removeEventListener('contextmenu', onContextMenuClick);
    };
  }, [handleClick, showContextMenu]);

  const isStatusValid = useCallback((tipo_status: number): boolean => {
    return tipo_status >= 2;
  }, []);

  return (
    <>
      {showContextMenu && (
        <ul
          className="menu"
          style={{
            top: anchorPoint.y,
            left: anchorPoint.x,
          }}
        >
          <li>
            <button
              type="button"
              onClick={() => {
                listOfFunctions[callableFunction]();
              }}
            >
              Atualizar este campo
            </button>
          </li>
          <li>
            <button type="button" onClick={() => listOfFunctions.attTodos()}>
              Atualizar todos os campos
            </button>
          </li>
          <hr className="divider" />
          <li>
            <button
              type="button"
              onClick={() => listOfFunctions.showDifferences()}
            >
              Ver diferenças
            </button>
          </li>
        </ul>
      )}
      <Accordion defaultActiveKey="0">
        <div className="row capa-tabs">
          <div className="col-12" style={{ paddingRight: '0px' }}>
            <CustomSeparator label="Capa" eventKey="1" onChange={() => null}>
              {showCapa === '0' && (
                <>
                  <button
                    type="button"
                    onClick={() => setKey('divergencias')}
                    className={key === 'divergencias' ? 'active' : ''}
                  >
                    Divergências
                  </button>
                  <button
                    type="button"
                    onClick={() => setKey('xml')}
                    className={key === 'xml' ? 'active' : ''}
                  >
                    XML
                  </button>
                  <button
                    type="button"
                    onClick={() => setKey('erp')}
                    className={key === 'erp' ? 'active' : ''}
                  >
                    ERP
                  </button>
                </>
              )}

              <ButtonCollapse
                type="button"
                isOppened={showCapa}
                onClick={() => {
                  setShowCapa((prev) => (prev === '0' ? '1' : '0'));
                }}
                className="active"
              >
                {showCapa === '1' && (
                  <BiChevronDown
                    color="#fff"
                    size={20}
                    style={{ marginTop: '-2px' }}
                  />
                )}
                {showCapa === '0' && (
                  <BiChevronUp
                    color="#fff"
                    size={20}
                    style={{ marginTop: '-2px' }}
                  />
                )}
              </ButtonCollapse>
            </CustomSeparator>
          </div>
        </div>
        <div id="accordionParams" className="accordion">
          <Accordion.Collapse
            as={Button}
            eventKey={showCapa}
            className="row params-data"
          >
            <div className="row data-comparison">
              <Tabs
                id="controlled-tab-cliente"
                activeKey={key}
                onSelect={(k) => setKey(`${k}`)}
              >
                <Tab eventKey="divergencias" title="">
                  <section>
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <InputText
                          id="attDescricao"
                          label="Descrição"
                          value={dadosErpDoFornecedor.des_fornecedor?.value.trim()}
                          placeholder="Descrição"
                          className={
                            dadosErpDoFornecedor.des_fornecedor?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          id="attIeRg"
                          label="RG/IE"
                          value={dadosErpDoFornecedor.num_rg_ie?.value.trim()}
                          placeholder="Insc. Estadual"
                          className={
                            dadosErpDoFornecedor.num_rg_ie?.invalid
                              ? 'capa-error'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          id="attCGC"
                          label="CNPJ/CPF"
                          value={dadosErpDoFornecedor.num_cpf_cnpj?.value.trim()}
                          placeholder="CNPJ/CPF"
                          setMask={
                            dadosErpDoFornecedor.num_cpf_cnpj?.value.length > 11
                              ? 'cnpj'
                              : 'cpf'
                          }
                          className={
                            dadosErpDoFornecedor.num_cpf_cnpj?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-9">
                        <InputText
                          id="attEndereco"
                          label="Endereço"
                          value={dadosErpDoFornecedor.des_logradouro?.value.trim()}
                          placeholder="Endereço"
                          className={
                            dadosErpDoFornecedor.des_logradouro?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-1">
                        <InputText
                          id="attNumEnd"
                          label="Número"
                          value={dadosErpDoFornecedor.num_endereco?.value}
                          placeholder="Número"
                          className={
                            dadosErpDoFornecedor.num_endereco?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          id="attBairro"
                          label="Bairro"
                          value={dadosErpDoFornecedor.des_bairro?.value.trim()}
                          placeholder="Bairro"
                          className={
                            dadosErpDoFornecedor.des_bairro?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          id="attCep"
                          label="CEP"
                          value={dadosErpDoFornecedor.num_cep?.value.trim()}
                          placeholder="CEP"
                          setMask="cep"
                          className={
                            dadosErpDoFornecedor.num_cep?.invalid
                              ? 'capa-error'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <InputText
                          id="attMunicipio"
                          label="Município"
                          value={dadosErpDoFornecedor.des_cidade?.value.trim()}
                          placeholder="Município"
                          className={
                            dadosErpDoFornecedor.des_cidade?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-1">
                        <InputText
                          id="attUF"
                          label="UF"
                          value={dadosErpDoFornecedor.des_uf?.value}
                          placeholder="UF"
                          className={
                            dadosErpDoFornecedor.des_uf?.invalid
                              ? 'capa-error'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          id="attFone"
                          label="Fone"
                          value={dadosErpDoFornecedor.num_telefone?.value}
                          placeholder="Telefone"
                          setMask={
                            dadosErpDoFornecedor.num_telefone?.value &&
                            dadosErpDoFornecedor.num_telefone?.value.length > 10
                              ? 'celular'
                              : 'telefone'
                          }
                          className={
                            dadosErpDoFornecedor.num_telefone?.invalid
                              ? 'capa-warning'
                              : ''
                          }
                          onChange={() => null}
                          isDisabled={dadosDaCapa.tipo_status >= 2}
                          onContextMenu={(e) => {
                            if (!isStatusValid(dadosDaCapa.tipo_status)) {
                              onContextMenuClick(e);
                            }
                          }}
                          title="Botão direito do mouse para + opções"
                        />
                      </div>
                    </div>
                  </section>
                </Tab>
                <Tab eventKey="xml" title="">
                  <section>
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <InputText
                          label="Descrição"
                          value={dadosDoXML?.fornecedor.xNome}
                          placeholder="Descrição"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="Insc. Estadual"
                          value={dadosDoXML?.fornecedor.IE}
                          placeholder="Insc. Estadual"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="CNPJ/CPF"
                          value={dadosDoXML?.fornecedor.CNPJ}
                          placeholder="CNPJ/CPF"
                          setMask={
                            dadosDoXML?.fornecedor.CNPJ &&
                            dadosDoXML?.fornecedor.CNPJ.length > 11
                              ? 'cnpj'
                              : 'cpf'
                          }
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-9">
                        <InputText
                          label="Endereço"
                          value={dadosDoXML?.fornecedor.enderEmit.xLgr}
                          placeholder="Endereço"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-1">
                        <InputText
                          label="Número"
                          value={dadosDoXML?.fornecedor.enderEmit.nro}
                          placeholder="Número"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="Bairro"
                          value={dadosDoXML?.fornecedor.enderEmit.xBairro}
                          placeholder="Loja"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="CEP"
                          value={dadosDoXML?.fornecedor.enderEmit.CEP}
                          placeholder="CEP"
                          setMask="cep"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <InputText
                          label="Município"
                          value={dadosDoXML?.fornecedor.enderEmit.xMun}
                          placeholder="Município"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-1">
                        <InputText
                          label="UF"
                          value={dadosDoXML?.fornecedor.enderEmit.UF}
                          placeholder="UF"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="Fone"
                          value={dadosDoXML?.fornecedor.enderEmit.fone}
                          placeholder="Fone"
                          setMask={
                            dadosDoXML?.fornecedor.enderEmit.fone &&
                            dadosDoXML?.fornecedor.enderEmit.fone.length > 10
                              ? 'celular'
                              : 'telefone'
                          }
                          onChange={() => null}
                        />
                      </div>
                    </div>
                  </section>
                </Tab>
                <Tab eventKey="erp" title="">
                  <section>
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <InputText
                          label="Descrição"
                          value={dadosErpDoFornecedor.des_fornecedor?.value}
                          placeholder="Descrição"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="Insc. Estadual"
                          value={dadosErpDoFornecedor.num_rg_ie?.value}
                          placeholder="Insc. Estadual"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="CNPJ/CPF"
                          value={dadosErpDoFornecedor.num_cpf_cnpj?.value}
                          placeholder="CNPJ/CPF"
                          setMask={
                            dadosErpDoFornecedor.num_cpf_cnpj?.value.length > 11
                              ? 'cnpj'
                              : 'cpf'
                          }
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-9">
                        <InputText
                          label="Endereço"
                          value={dadosErpDoFornecedor.des_logradouro?.value}
                          placeholder="Endereço"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-1">
                        <InputText
                          label="Número"
                          value={dadosErpDoFornecedor.num_endereco?.value}
                          placeholder="Número"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="Bairro"
                          value={dadosErpDoFornecedor.des_bairro?.value}
                          placeholder="Bairro"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="CEP"
                          value={dadosErpDoFornecedor.num_cep?.value}
                          placeholder="CEP"
                          setMask="cep"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <InputText
                          label="Município"
                          value={dadosErpDoFornecedor.des_cidade?.value}
                          placeholder="Município"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-1">
                        <InputText
                          label="UF"
                          value={dadosErpDoFornecedor.des_uf?.value}
                          placeholder="UF"
                          onChange={() => null}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <InputText
                          label="Fone"
                          value={dadosErpDoFornecedor.num_telefone?.value}
                          placeholder="Telefone"
                          setMask={
                            dadosErpDoFornecedor.num_telefone?.value &&
                            dadosErpDoFornecedor.num_telefone?.value.length > 10
                              ? 'celular'
                              : 'telefone'
                          }
                          onChange={() => null}
                        />
                      </div>
                    </div>
                  </section>
                </Tab>
              </Tabs>
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};
