import React, { useCallback } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { CustomSeparator } from '../../Components';
import { useValidadorXML } from '../../ValidadorContext';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { GrDocumentText } from 'react-icons/gr';
import { MdOutlineSentimentSatisfied } from 'react-icons/md';

import JSONStringify from 'json-stringify-pretty-compact';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/webpack-resolver';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container } from './styles';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { tableItensColumns } from './TableItens';
import { toast } from 'react-toastify';
import { pd } from 'pretty-data';
import { TooltipStatusValidacao } from '../../Components/Tooltips/StatusValidacao';

const MySwal = withReactContent(Swal);

export const ItensDaNF: React.FC = () => {
  const {
    dadosDaCapa,
    showTable,
    setShowTable,
    itensDaTabela,
    setShowModalDeCorrecoes,
    camposAlterados,
    dadosDoXML,
    onSetItemACorrigir,
  } = useValidadorXML();

  const CreateTable = useCallback((produto: any): void => {
    const formattedXml = pd.xml(produto.asXML);
    const AlertTable = (
      <AceEditor
        placeholder="Placeholder Text"
        mode="xml"
        theme="solarized_dark"
        name="blah2"
        fontSize={14}
        showPrintMargin={false}
        showGutter={false}
        highlightActiveLine
        value={formattedXml}
        readOnly
        width="100%"
        height="600px"
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: false,
          tabSize: 2,
          wrap: true,
        }}
      />
    );

    MySwal.fire({
      icon: 'info',
      width: 1200,
      title: 'Informações do item',
      html: AlertTable,
      customClass: {
        header: 'customalert-header',
        icon: 'customalert-icon',
        title: 'customalert-title',
      },
    });
  }, []);

  const handleShowItemXML = useCallback(
    (nItem: string) => {
      const itemData = dadosDoXML?.itens[0].find((item: any) => {
        return item.nItem === nItem;
      });
      CreateTable(itemData);
    },
    [CreateTable, dadosDoXML],
  );

  const isStatusValid = useCallback((tipo_status: number): boolean => {
    return tipo_status >= 2;
  }, []);

  /**
   * Criar um variavel para columns e uma para rows
   */
  const columns: CustomGridColumns[] = [...tableItensColumns];

  return (
    <Container>
      <Accordion defaultActiveKey="0">
        <div className="row accordion-row">
          <div className="col-12" style={{ paddingRight: '0px' }}>
            <CustomSeparator
              label="Itens da Nota"
              eventKey={showTable}
              onChange={(newEventKey) => {
                setShowTable(newEventKey);
              }}
            />
          </div>
        </div>
        <div id="accordionTable" className="accordion">
          <Accordion.Collapse
            as={Button}
            eventKey={showTable}
            className="row params-data"
          >
            <>
              <div className="col-sm-12 col-md-12 mt-4 itensTable">
                <TableContainer
                  style={{
                    height: '500px',
                    overflowY: 'auto',
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell className="sticky-col first-col">
                          XML
                        </TableCell>
                        <TableCell className="sticky-col second-col">
                          Status
                        </TableCell>
                        {columns
                          .filter((column) => column.hide !== true)
                          .map((column) => {
                            if (column.field === 'cod_produto') {
                              return (
                                <TableCell
                                  className="sticky-col third-col"
                                  key={column.field}
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {column.headerName}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                key={column.field}
                                style={{
                                  whiteSpace: 'nowrap',
                                  // position: 'relative',
                                  // zIndex: 2,
                                }}
                              >
                                {column.headerName}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itensDaTabela.map((item: any) => {
                        let isInformativo = false;
                        let msgImpeditiva = 'Impeditivo';
                        let msgInformativa = 'Informativo';

                        if (item.statusDaValidacao.length > 0) {
                          msgImpeditiva += '\n';
                          msgInformativa += '\n';

                          item.statusDaValidacao.forEach(
                            (status: any, idx: number) => {
                              if (
                                status.messageStatus.includes(
                                  'Tributação do XML difere da cadastrada no NCM - Cód Tributação',
                                )
                              ) {
                                status.restricao = 'INF';
                                isInformativo = true;
                              } else {
                                isInformativo = false;
                              }

                              const numItem = idx + 1;

                              if (status.restricao === 'INF') {
                                msgInformativa += `${numItem} - ${status.messageStatus}\n`;
                              } else if (
                                status.restricao === 'BLOQ' ||
                                status.restricao === 'NE'
                              ) {
                                msgImpeditiva += `${numItem} - ${status.messageStatus}\n`;
                              }
                            },
                          );
                        }

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={item.index}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!isStatusValid(dadosDaCapa.tipo_status)) {
                                onSetItemACorrigir(item);
                                setShowModalDeCorrecoes(true);
                              } else {
                                toast.warn(
                                  `XML já Confirmado. Para editar clique em 'Validar' na sessão 'Parâmetros de Validação'.`,
                                );
                              }
                            }}
                            style={{
                              borderColor: '#8850BF',
                              cursor: !isStatusValid(dadosDaCapa.tipo_status)
                                ? 'pointer'
                                : '',
                            }}
                          >
                            <TableCell
                              className="sticky-col-row first-col"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleShowItemXML(item.index);
                              }}
                              title="Informações do XML"
                              style={{ zIndex: 0 }}
                            >
                              <GrDocumentText />
                            </TableCell>
                            <TableCell className="second-col">
                              {(item.qtdStatusBloq >= 1 ||
                                item.qtdStatusNe >= 1 ||
                                item.qtdStatusMix >= 1) &&
                                !isInformativo && (
                                  <TooltipStatusValidacao
                                    tipo="BLOQ"
                                    mensagem={msgImpeditiva}
                                  />
                                )}
                              {isInformativo && (
                                <TooltipStatusValidacao
                                  tipo="INF"
                                  mensagem={msgInformativa}
                                />
                              )}
                              {!isInformativo &&
                                item.qtdStatusBloq === 0 &&
                                item.qtdStatusNe === 0 &&
                                item.qtdStatusMix === 0 &&
                                item.qtdStatusInf >= 1 && (
                                  <TooltipStatusValidacao
                                    tipo="INF"
                                    mensagem={msgInformativa}
                                  />
                                )}
                              {item.qtdStatusBloq === 0 &&
                                item.qtdStatusNe === 0 &&
                                item.qtdStatusMix === 0 &&
                                item.qtdStatusInf === 0 && (
                                  <MdOutlineSentimentSatisfied
                                    color="#00ccff"
                                    title="Ok"
                                  />
                                )}
                            </TableCell>
                            {columns
                              .filter((column) => column.hide !== true)
                              .map((column) => {
                                const campoAlterado = camposAlterados.find(
                                  (campo) =>
                                    campo.index === item.index &&
                                    campo.campo === column.field,
                                );
                                if (column.field === 'cod_produto') {
                                  const codProduto = item[column.field];
                                  return (
                                    <TableCell
                                      className={
                                        campoAlterado
                                          ? 'sticky-col-row third-col editado'
                                          : 'sticky-col-row third-col'
                                      }
                                      title="Copiar PLU"
                                      key={column.field}
                                      style={{
                                        whiteSpace: 'nowrap',
                                        zIndex: 0,
                                        backgroundColor: 'white',
                                        // borderRight: '1px solid #e8e8e8',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(
                                          codProduto,
                                        );
                                        toast.success(
                                          'Copiado para área de transferência',
                                        );
                                      }}
                                    >
                                      {column.renderCell
                                        ? column.renderCell(item)
                                        : item[column.field]}
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    className={campoAlterado ? 'editado' : ''}
                                    key={column.field}
                                    style={{
                                      whiteSpace: 'nowrap',
                                      backgroundColor: 'white',
                                    }}
                                    title={`${item.index} - Código Produto: ${item.cod_produto} - ${item.des_produto}`}
                                  >
                                    {column.renderCell
                                      ? column.renderCell(item)
                                      : item[column.field]}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </Container>
  );
};
