import React from 'react';
import ValidadorXMLContent from './ValidadorContent';
import { ValidadorXMLContextProvider } from './ValidadorContext';

export const ValidadorXML: React.FC = () => {
  return (
    <ValidadorXMLContextProvider>
      <ValidadorXMLContent />
    </ValidadorXMLContextProvider>
  );
};
