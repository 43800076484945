import styled from 'styled-components';

interface Props {
  isOppened: string;
}

export const ButtonCollapse = styled.button<Props>`
  cursor: pointer;

  padding: 1px 8px 1px 8px !important;
  float: right;
  background-color: #8850bf;
  border: none;
  border-radius: 2px;
  transition: 0.2s;
  color: #fcfcfe;
  width: 36px;

  &:focus {
    outline: none !important;
    outline: 0px !important;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #57069e !important;
    color: #000;
    transition: 0.2s;
  }
`;
